<template>
  <div>
	<div class="leadProgram">
		<Loader v-if="!group" />
		<div class="leadProgram__col1" style="width: calc(100% - 10vw); min-height: 100vh;" v-if="group && submitted">
			<div class="shiftCard mb-5" style="max-width:480px;">
				<h3>Thank you!</h3>
				<p>We will be in touch.</p>
				
			</div>
			<div class="shiftCard mb-5" style="max-width:480px;">
				<div class="nav__logo">
          <span>J<em>u</em>mp</span>
        </div>
        <p>Create a password to create your account and complete your application. You will also get access to all of our other gig and career opportunities:</p>
				<div class="mb-3">
          <label for="password2">Create a Password</label>
          <input v-model.trim="form.password" autocomplete="new-password" type="password" placeholder="min 6 characters" id="password2" required minlength="6" />
        </div>
        <button class="btn btn__dark btn__xlarge mt-3" :disabled="!form.password" @click="signupGroup()">
        Submit
        <transition name="fade">
          <span class="ml-2" v-if="performingRequest">
          <i class="fa fa-spinner fa-spin"></i>
          </span>
        </transition>
      </button>
			</div>
			
		</div>
		<div class="leadProgram__col1" style="width: calc(100% - 10vw); min-height: 100vh;" v-if="group && !submitted">
			<form @submit.prevent style="max-width: 640px; margin:auto;">
	  		<div class="shiftCard mb-5">
	  			<div class="nav__logo">
	          <span>J<em>u</em>mp</span>
	        </div>
	  			<h1 v-if="group.title">{{group.title}}</h1>
	  			<h5 v-if="group.location">Job Application / {{group.location}}</h5>
	  			<div v-if="group.job && group.job.length >= 1" class="mt-3">
	  				<button class="chip mr-3 mb-3" v-for="(item, index) in group.job" :key="index">{{item.title}} | ${{group.startingPay}}/hr</button>
	  			</div>
	  			<div v-if="group.type && group.type.length >= 1">
	  				<button class="darkChip mr-3 mb-3" v-for="(item, index) in group.type" :key="index">{{item}}</button>
	  			</div>

	  			<p v-if="!group.description">We are actively hiring in {{group.location}}. Please complete the interest form below and we will be in touch right away.</p>
	  			<div v-if="group.description" v-html="group.description"></div>
	  		</div>
	  		<div class="shiftCard mb-5">
	  			<label for="firstname">First Name:</label>
          <input v-model.trim="form.first" autocomplete="given-name" type="text" placeholder="" id="firstname" required />
          
	  		</div>
	  		<div class="shiftCard mb-5">
	  			<label for="lastname">Last Name:</label>
          <input v-model.trim="form.last" autocomplete="family-name" type="text" placeholder="" id="lastname" required />
	  		</div>
	  		<div class="shiftCard mb-5">
	  			<label for="email">Email:</label>
          <input v-model.trim="form.email" autocomplete="email" type="text" placeholder="" id="email" required />
	  		</div>
	  		<div class="shiftCard mb-5">
	  			<label for="phone">Cell Phone</label>
           <input v-model.trim="form.phone" type="text" v-mask="'(###) ###-####'" placeholder="(###) ###-####" id="phone" required />
<!-- 	  			<label for="phone">Phone:</label>
          <input v-model.trim="form.phone" autocomplete="given-name" type="text" placeholder="" id="phone" required /> -->
	  		</div>

	  		<div class="shiftCard mb-5">
              <label for="dob">Date of Birth:</label>
              <div style="width:100%;">
                <input type="date" placeholder="" v-model.trim="form.dob" id="dob" required/>
              </div>
            </div>
         <!-- <div class="shiftCard mb-5" v-if="isNewYork">
	      	<label for="shiftPreference">What is your preferred shift?</label>
	      	<div class="caption mb-2">First shift will be approximately 7:00 AM - 3:30 PM, Second Shift 3:00 PM - 11:30 PM, and Third Shift 11:00 PM - 7:30 AM. You can select more than one.</div>
	      	<v-select
	          label="title" 
	          :options="shifts"
	          :clearable=true
						taggable
						multiple
	          v-model="form.shiftPreference"
	          >
	        </v-select>
	      </div> -->
	  		<div class="shiftCard mb-5" v-if="!isNewYork">
	  			<label for="hours">How many hours are you looking to work each week?</label>
	        <v-select
	          label="title" 
	          :options="hours"
	          multiple
	          v-model="form.hours"
	          >
	        </v-select>
	  		</div>
	  		<div class="shiftCard mb-5" v-if="group && group.amazon">
          <label for="legal">Have you ever worked at an Amazon location before?</label>
           <v-select
            label="title" 
            :options="yesNo"
            v-model="form.amazon"
            >
          </v-select>
        </div>
	  		<div class="shiftCard mb-5">
	  			<label for="legal">Are you legally allowed to work in the United States?</label>
	        <v-select
	          label="title" 
	          :options="yesNo"
	          v-model="form.legal"
	          >
	        </v-select>
	  		</div>
	  		<div class="shiftCard mb-5">
        <label for="standing">Are you comfortable standing and walking for long periods of time?</label>
	        <v-select
	          label="title" 
	          :options="yesNo"
	          v-model="form.standing"
	          >
	        </v-select>
	      </div>
	  		<div class="shiftCard mb-5">
	  			<label for="forty">Are you able to lift and move a box that weighs 40lbs?</label>
	        <v-select
	          label="title" 
	          :options="yesNo"
	          v-model="form.forty"
	          >
	        </v-select>
	  		</div>
	  		<div class="shiftCard mb-5" v-if="!isNewYork">
	        <label for="background">Is there anything we should be aware of when we run your background check?</label>
	        <v-select
	          label="title" 
	          :options="yesNo"
	          v-model="form.background"
	          >
	        </v-select>
	        <transition name="fade">
	          <div class="mt-3" v-if="form.background == 'Yes'">
	            <label for="explain">Please explain:</label>
	            <input type="text" placeholder="" v-model.trim="form.explain" id="explain" />
	          </div>
	        </transition>
	      </div>
	      <div class="shiftCard mb-5" v-if="!isNewYork">
	        <label for="passDrugTest">Are you confident that you would pass a drug test?</label>
	        <v-select
	          label="title" 
	          :options="yesNo"
	          v-model="form.passDrugTest"
	          >
	        </v-select>
	      </div>
	      
	      <div class="shiftCard mb-5">
	        <label for="transportation">Do you have reliable transportation?</label>
	        <v-select
	          label="title" 
	          :options="yesNo"
	          v-model="form.transportation"
	          >
	        </v-select>
	      </div>
	      <div class="shiftCard mb-5">
	        <label for="nightsWeekends">Are you able to work nights or weekends?</label>
	        <v-select
	          label="title" 
	          :options="yesNoSometimes"
	          v-model="form.nightsWeekends"
	          >
	        </v-select>
	      </div>
	  		<div class="shiftCard mb-5">
	  			<label for="experience">Tell us about your experience or anything else we should know about you:</label>
        	<textarea name="experience" id="" cols="30" rows="6" v-model.trim="form.experience"></textarea>
	  		</div>

	  		<div class="shiftCard mb-5">
	  			<label for="location">Your Address (or at least city and state)</label>
          <label>
            <gmap-autocomplete
              class="v-input v-input__control outline-fix"
              @place_changed="setPlace" placeholder="Enter Your Location">
            </gmap-autocomplete>
          </label>
        </div>
	  		<div class="shiftCard mb-5">
          <label for="password2">Create a Password</label>
          <input v-model.trim="form.password" autocomplete="new-password" type="password" placeholder="min 6 characters" id="password2" required minlength="6" />
        </div>

        

        <!-- <button v-if="isNewYork" class="btn btn__dark btn__xlarge mt-3 mb-5" :disabled="!valid" @click="signupGroupNY()">
          Submit
          <transition name="fade">
            <span class="ml-2" v-if="performingRequest">
            <i class="fa fa-spinner fa-spin"></i>
            </span>
          </transition>
        </button> -->

        <button class="btn btn__dark btn__xlarge mt-3 mb-5" :disabled="!valid" @click.once="signupGroup()">
          Submit
          <transition name="fade">
            <span class="ml-2" v-if="performingRequest">
            <i class="fa fa-spinner fa-spin"></i>
            </span>
          </transition>
        </button>

        <div class="caption mt-5 shiftCard mb-5">
        	<div class="mb-3">Jump is an Equal Opportunity Employer. Our policy is clear: there shall be no discrimination on the basis of age, disability, sex, race, religion or belief, gender reassignment, marriage/civil partnership, pregnancy/maternity, or sexual orientation.
        		</div>

					<div>We are an inclusive organization and actively promote equality of opportunity for all with the right mix of talent, skills and potential. We welcome all applications from a wide range of candidates. Selection for roles will be based on individual merit alone.</div>
        </div>


	  		<!-- <button class="btn btn__dark btn__xlarge mt-3" :disabled="!valid" @click="submitForm()">
          Submit
          <transition name="fade">
            <span class="ml-2" v-if="performingRequest">
            <i class="fa fa-spinner fa-spin"></i>
            </span>
          </transition>
        </button> -->
	  		
  		</form>

  	</div>
    
	</div>
  <Everify />
</div>
</template>

<style scoped>
	.google-form {

	}
	.leadProgram__col1 {
    background: #00c87f;
    width: 100%;
    padding: 6rem 5vw 6rem 5vw;
    color: #333 !important;
}
.shiftCard {
	background: white; padding: 2rem;
}
</style>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import router from '@/router'
import Everify from '@/components/Everify.vue'

export default {
  name: 'groupForm',
  data: () => ({
    performingRequest: false,
    hours: ['0-10', '10-20', '20-30', '30-40'],
    yesNo: ['Yes', 'No'],
    yesNoSometimes: ['Yes', 'No', 'Sometimes'],
    form: {},
    submitted: false,
    center: { lat: 45.508, lng: -73.587 },
    marker: {},
    address: {},
    place: '',
    shifts: ['First Shift', 'Second Shift', 'Third Shift']
  }),
  created () {
    this.$store.dispatch("getGroupFromId", this.$route.params.id)
  },
  computed: {
    ...mapState(['group', 'currentUser']),
    valid() {
    	if (this.form.first && this.form.last && this.form.email && this.form.phone && this.form.standing && this.form.forty && this.form.transportation && this.form.password && this.form.password.length >= 6 && this.address && this.address.city) {
    		return true
    	} else {
    		return false
    	}
    },
    isNewYork() {
    	if (this.group && this.group.id && 
    		(this.group.id == 'X6d0ja8KTEzrALDX8D5j' || this.group.id == 'KMmMi9tTurFEWG049muQ' || this.group.id == 't9M3jG88eK6RIWPP7JkL' || this.group.id == 'LqkibqVT2aMLBvlzYtDn' || this.group.id == 'Rfq9OFBzRHPRUsLOHBgO' || this.group.id == 'QxmLIFJd5vaxXC94a2Vl')


    		) {
    		return true
    	} else {
    		return false
    	}
    }
  },
  components: {
  	Loader,
    Everify
  },
  methods: {
  	setPlace(place) {
      this.currentPlace = place
        if (this.currentPlace) {
          const marker = {
            lat: this.currentPlace.geometry.location.lat(),
            lng: this.currentPlace.geometry.location.lng()
          };
          this.marker = {position: marker}
          this.place = this.currentPlace
          this.center = marker;
          this.currentPlace = null;
        }
        if (place.address_components) {
          this.performingRequest = true
          this.address = {}
          setTimeout(() => {
              this.performingRequest = false
          }, 1000)
          console.log(this.address)
          var components = place.address_components;
          for (var i = 0, component; component = components[i]; i++) {
          if (component.types[0] == 'street_number') {
              this.address.street_number = component['long_name'];
          }
          if (component.types[0] == 'route') {
              this.address.street = component['long_name'];
          }
          if (component.types[0] == 'locality' || component.types[0] == 'sublocality_level_1' || component.types[0] == 'natural_feature') {
              this.address.city = component['long_name'];
          }
          if (component.types[0] == 'administrative_area_level_1') {
              this.address.state = component['short_name'];
          }
          if (component.types[0] == 'country') {
              this.address.country = component['short_name'];
          }
          if (component.types[0] == 'postal_code') {
              this.address.zip = component['short_name'];
          }
        }
      }
      this.place = ''
      this.$emit('clicked', this.valid)
    },
    // submitForm() {
    //   this.performingRequest = true
    //   let form = this.form
    //   let theLocation = this.group.location.split(", ")
    //   form.market = {
    //   	city: theLocation[0],
    //   	state: theLocation[1]
    //   }
    //   form.event = this.group
    //   form.source = 'our website'
    //   form.status = 'New'
    //   console.log(form)
    //   let store = this.$store
    //   store.dispatch('newLeadSubmission', form)
    //   setTimeout(() => {
    //     this.performingRequest = false
    //     this.submitted = true
    //   }, 2000)
    // },
    // async signup() {
    // 	this.performingRequest = true
    // 	await this.$store.dispatch('signupForm', {
    //     email: this.form.email,
    //     password: this.form.password,
    //     firstName: this.form.first,
    //     phone: this.form.phone,
    //     lastName: this.form.last,
    //     dob: this.form.dob,
    //     interview: this.form,
    //     shift: this.shiftInfo,
	  //     day: this.shiftInfo.day,
	  //     event: this.eventInfo,
	  //     address: this.address,
	  //     center: this.center,
    //   })


    //   await this.$store.dispatch('addJobAvailabilityFromSignup', {
    //     event: this.eventInfo,
    //     shift: this.shiftInfo,
    //     type: this.shiftInfo.type,
    //     user: this.form
    //   });

    // 	setTimeout(() => {
    // 		this.form = {}
    // 		this.address = {}
    // 		this.performingRequest = false
    //     // this.$router.push('/auth/verify')
    //   }, 12000)
    // },
    async signupGroup() {
    	this.performingRequest = true
    	await this.$store.dispatch('signupFormGroup', {
        email: this.form.email,
        password: this.form.password,
        password: this.form.password,
        firstName: this.form.first,
        phone: this.form.phone,
        lastName: this.form.last,
        dob: this.form.dob,
        interview: this.form,
        group: this.$route.params.id,
        address: this.address,
	      center: this.center,
	      shiftPreference: this.form.shiftPreference
      })
    	this.form = {}
			this.address = {}
      this.submitted = true
			this.performingRequest = false
    },
    // async signupGroupNY() {
    // 	console.log('signupFormGroupNY')
    // 	this.performingRequest = true
    // 	await this.$store.dispatch('signupFormGroupNY', {
    //     email: this.form.email,
    //     password: this.form.password,
    //     password: this.form.password,
    //     firstName: this.form.first,
    //     phone: this.form.phone,
    //     lastName: this.form.last,
    //     dob: this.form.dob,
    //     interview: this.form,
    //     group: this.$route.params.id,
    //     address: this.address,
	  //     center: this.center,
	  //     shiftPreference: this.form.shiftPreference,
	  //     location: this.group.location
    //   })
    // 	this.form = {}
		// 	this.address = {}
		// 	this.performingRequest = false
    // },
  },
  beforeDestroy () {
    this.performingRequest = null
    this.$store.dispatch("clearGroupState")
    this.form = {}
    delete this.form
    delete this.performingRequest
  },
}
	
</script>